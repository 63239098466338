import styled from "styled-components";

export const MainNav = styled.nav`
  background: #f8f8f8;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
`;

export const LoginGradientButton = styled.button`
  top: 20px;
  left: 1129px;
  width: 98px;
  height: 45px;
  /* UI Properties */
  background: transparent
    linear-gradient(224deg, var(--unnamed-color-9966ff) 0%, #4d3380 100%) 0% 0%
    no-repeat padding-box;
  background: transparent linear-gradient(224deg, #9966ff 0%, #4d3380 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  color: #fff;
  border: 1px solid #9966ff;
  font-weight: bold;
  &:hover {
    background: #e9e4f2;
    color: #9966ff;
  }
`;

export const GradientButton = styled.button`
  top: 20px;
  left: 1129px;
  width: 98px;
  height: 45px;
  /* UI Properties */
  background: "#70e374";
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  opacity: 1;
  color: #fff;
  border: 1px solid #9966ff;
  font-weight: bold;
  &:hover {
    background: #e9e4f2;
    color: #9966ff;
  }
`;
