import React from 'react';
import './components/styles.css';
import { useState } from 'react';
import Hero from './components/Hero.js';

const IdeasMart = () => {
  const images = [
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886223/ideasmart/photo_6_2023-11-13_17-36-00_gsewgs.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886221/ideasmart/photo_5_2023-11-13_17-36-00_nfseoe.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886219/ideasmart/photo_4_2023-11-13_17-36-00_wg8umd.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886218/ideasmart/photo_3_2023-11-13_17-36-00_j0qj9e.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886216/ideasmart/photo_2_2023-11-13_17-36-00_qviffi.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886215/ideasmart/photo_1_2023-11-13_17-36-00_dx1pkb.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886214/ideasmart/photo_27_2023-11-13_17-36-01_lqamem.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886212/ideasmart/photo_26_2023-11-13_17-36-01_bghgzg.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886210/ideasmart/photo_25_2023-11-13_17-36-01_bsqa4s.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886210/ideasmart/photo_24_2023-11-13_17-36-01_m2mxs5.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886207/ideasmart/photo_23_2023-11-13_17-36-01_wm0ydf.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886206/ideasmart/photo_22_2023-11-13_17-36-01_gif9fk.jpg',

  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      <div style={{paddingTop:"100px",paddingBottom:"25px"}}><Hero/></div>
      <p>IdeasMart a one-of-a-kind platform designed for brainstorming and discussing ideas that foster growth. In an effort to empower the younger generation and contribute to the development of the nation, Dan Energy hosts this unique platform every Thursday at 1:30 PM EAT. The primary objective of this program is to generate marketable innovative ideas that can fuel growth and offer solutions to the challenges faced by our country.</p>
      <div className="image-grid" >
        {images.map((image, index) => (
          <div key={index} className="image-card" >
            <img
              src={image}
              alt={`Image ${index + 1}`}
              onClick={() => handleClick(image)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="modal">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <img src={selectedImage} alt="Full Image" />
        </div>
      )}
    </div>
  );
};

export default IdeasMart;
