import { render } from "@testing-library/react";
import React from "react";
import { Helmet } from "react-helmet";

const BlogPostHead = ({ title, description, image, url }) => {
return (
    <Helmet>
        
          
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:title" content={title} data-react-helmet="true"/>
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:url" content={url} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@yourusername" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
  </Helmet>
    
  );
};

export default BlogPostHead;
