import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DanFoundation from "../dan foundation/DanFoundation";
import MainNavigation from "../utilities/components/MainNavigation";
import SatSquare from "../sat square/SatSquare";
import SatCoding from "../sat coding/SatCoding";
import CarePad from "../care pad/CarePad";
import ZelalemArticle from "../Article/ZelalemArticle";
import SundayCoding from "../sunday coding/SundayCoding";
import SundayMent from "../sunday ment/SundayMent";
import SatMent from "../sat ment/Sat Ment";
import IdeasMart from "../ideasmart/IdeasMart";
import ArtTech from "../art tech/ArtTech";
import AbrehamArticle from "../Article/AbrehamArticle";
import MenalArticle from "../Article/MenalArticle";
import ZaidArticle from "../Article/ZaidArticle";

//imports
const Home = React.lazy(() => import("../home/Home"));
const About = React.lazy(() => import("../about/About"));
const AgriTech = React.lazy(() => import("../agriTech/AgriTech"));
const Ai = React.lazy(() => import("../AI/Ai"));
const Research_and_development = React.lazy(() =>
  import("../R&D/Research_and_development")
);
const Security_labs = React.lazy(() =>
  import("../security labs/Security_labs")
);
const Fin_tech = React.lazy(() => import("../Fin Tech/Fin_tech"));
const Cloud = React.lazy(() => import("../cloud/Cloud"));
const Animation = React.lazy(() => import("../animation/Animation"));
const ErpCrm = React.lazy(() => import("../erpcrm/ErpCrm"));
const FinanceAndAccounting = React.lazy(() =>
  import("../finance and accounting/FinanceAndAccounting")
);
const DigitalMarketing = React.lazy(() =>
  import("../digital marketing/DigitalMarketing")
);
const DataProcessing = React.lazy(() =>
  import("../data processing/DataProcessing")
);
const Contact = React.lazy(() => import("../contact/Contact"));
const Csr = React.lazy(() => import("../CSR/Csr"));
const Erp = React.lazy(() => import("../ERP/Erp"));
const Services = React.lazy(() => import("../services/services"));
const Gallery = React.lazy(() => import("../gallery/Gallery"));
const Blog = React.lazy(() => import("../blog/Blog"));
const Vacancy = React.lazy(() => import("../vacancy/Vacancy"));
const EnvironmentalEngineer = React.lazy(() => import("../vacancy/component/detail/environmentalengineer"));
const TraineeEngineer = React.lazy(() => import("../vacancy/component/detail/trainee_engineer"));
const DANDMPO = React.lazy(() => import("../dan dmpo/DAN DMPO"));
const CyberSecurity = React.lazy(() => import("../cyber/CyberSecurity"));
const Training_and_placement = React.lazy(() =>
  import("../Training and Placement/Training_and_placement")
);
const HealthTech = React.lazy(() => import("../Health Tech/HealthTech"));
const VentureCapital = React.lazy(() =>
  import("../Venture Capital/VentureCapital")
);
const ELearning = React.lazy(() => import("../eLearning/ELearning"));
const ClientAndOperationsProtocol = React.lazy(() =>
  import("../clientandoperationsprotocol/ClientAndOperationsProtocol")
);
const PrivacyPolicy = React.lazy(() =>
  import("../Privacy Policy/privacypolicy")
);
const Article = React.lazy(() => import("../Article/Article"));

//

//routes
const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainNavigation />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/artificial_intelligence" element={<Ai />} />
        <Route
          path="/research_and_development"
          element={<Research_and_development />}
        />
        <Route path="/security_labs" element={<Security_labs />} />
        <Route path="/fin_tech" element={<Fin_tech />} />
        <Route path="/agritech" element={<AgriTech />} />
        <Route path="/corporate-social-responsibility" element={<Csr />} />
        <Route path="/animation" element={<Animation />} />
        <Route path="/digitalmarketing" element={<DigitalMarketing />} />
        <Route path="/erpcrm" element={<ErpCrm />} />
        <Route path="/erp" element={<Erp />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/vacancy" element={<Vacancy />} />
        <Route path="/EnvironmentalEngineer" element={<EnvironmentalEngineer />} />
        <Route path="/TraineeEngineer" element={<TraineeEngineer />} />
        <Route path="/finance" element={<FinanceAndAccounting />} />{" "}
        <Route path="/cloud" element={<Cloud />} />{" "}
        <Route path="/dataprocessing" element={<DataProcessing />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/dandmpo" element={<DANDMPO />} />
        <Route path="/dan_foundation" element={<DanFoundation />} />
        <Route path="/sat_square" element={<SatSquare/>}/>
        <Route path="/sat_coding" element={<SatCoding/>}/>
        <Route path="/carepad" element={<CarePad/>}/>
        <Route path="/arttech" element={<ArtTech/>}/>
        <Route path="/sundaycoding" element={<SundayCoding/>}/>
        <Route path="/sundayment" element={<SundayMent/>}/>
        <Route path="/satment" element={<SatMent/>}/>
        <Route path="/ideasmart" element={<IdeasMart/>}/>
        <Route path="/cybersecurity" element={<CyberSecurity />} />
        <Route
          path="/clientandoperationsprotocol"
          element={<ClientAndOperationsProtocol />}
        />
        <Route path="/elearning" element={<ELearning />} />
        <Route
          path="/training_and_placement"
          element={<Training_and_placement />}
        />
        <Route path="/health_tech" element={<HealthTech />} />
        <Route path="/ventureCapital" element={<VentureCapital />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />


        <Route path="/article" element={<Article />} />
        <Route path="/zelalemarticle" element={<ZelalemArticle />} />
        <Route path="/abrehamarticle" element={<AbrehamArticle />} />
        <Route path="/menalarticle" element={<MenalArticle />} />
        <Route path="/zaidarticle" element={<ZaidArticle />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
};

export default AllRoutes;
