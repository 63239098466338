import { Img } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

const Logo = ({ white }) => {
  const logo =
    "https://res.cloudinary.com/ddyvv0new/image/upload/v1665349250/e1-red-black-min_xqqxiy.gif";

  return (
    <NavLink to="/">
      <Img
        src={logo}
        h={{ base: "80px", sm: "80px", md: "80px", "2xl": "90px" }}
      />
    </NavLink>
  );
};

export default Logo;
