import React from 'react';
import './components/styles.css';
import { useState } from 'react';
import Hero from './components/Hero.js';

const SundayCoding = () => {
  const images = [
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699884008/sundaycoding/photo_6_2023-11-13_16-58-22_alcww1.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699884007/sundaycoding/photo_1_2023-11-13_16-58-22_baj7ol.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699884006/sundaycoding/photo_12_2023-11-13_16-58-22_fhsgm7.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699884006/sundaycoding/photo_7_2023-11-13_16-58-22_tsuxe1.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699884005/sundaycoding/photo_2_2023-11-13_16-58-22_x2bddp.jpg',
    // 'https://res.cloudinary.com/ddyvv0new/image/upload/v1699884004/sundaycoding/photo_9_2023-11-13_16-58-22_pgoz0g.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699884005/sundaycoding/photo_5_2023-11-13_16-58-22_kydwml.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699884004/sundaycoding/photo_10_2023-11-13_16-58-22_cqp9is.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699884003/sundaycoding/photo_4_2023-11-13_16-58-22_bh6kop.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699884004/sundaycoding/photo_3_2023-11-13_16-58-22_izb7d8.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699884004/sundaycoding/photo_11_2023-11-13_16-58-22_fjwrta.jpg',


  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      <div style={{paddingTop:"100px",paddingBottom:"25px"}}><Hero/></div>
      <p>Dan Energy presents SundayCoding, a platform created as an outreach initiative to empower the younger generation. Each Sunday morning, passionate volunteers lead a program dedicated to teaching coding skills. This unique opportunity is extended to youth aged 14-18, who are picked up from their neighborhoods by buses. By immersing themselves in the world of computers and discovering the intricacies of coding, these young individuals, who face limitations in reaching our company on their own, are given a chance to actively contribute to the ongoing digital technology revolution.</p>
      <div className="image-grid" >
        {images.map((image, index) => (
          <div key={index} className="image-card" >
            <img
              src={image}
              alt={`Image ${index + 1}`}
              onClick={() => handleClick(image)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="modal">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <img src={selectedImage} alt="Full Image" />
        </div>
      )}
    </div>
  );
};

export default SundayCoding;
