import { Stack } from "@chakra-ui/react";
import React from "react";
import Hero from "./components/Hero";
import Article from "./components/Zelalem/Article";
const ZelalemArticle = () => {
  return (
    <Stack>
      <Hero />
      <Article /> 
    </Stack>
  );
};

export default ZelalemArticle;
