import React from "react";

const MenalArticle = () => {
  const handleTwitterSearch = (query) => {
    window.open(
      `https://twitter.com/search?q=${encodeURIComponent(
        query
      )}&src=typed_query`,
      "_blank"
    );
  };

  const renderContentWithLinks = (content) => {
    const words = content.split(" ");

    return words.map((word, index) => {
      if (word.startsWith("#")) {
        const hashtag = word.slice(1);
        return (
          <span key={index}>
            <a onClick={() => handleTwitterSearch(`#${hashtag}`)}>{word}</a>{" "}
          </span>
        );
      } else if (word.startsWith("@")) {
        const username = word.slice(1);
        return (
          <span key={index}>
            <a onClick={() => handleTwitterSearch(`@${username}`)}>{word}</a>{" "}
          </span>
        );
      } else {
        return `${word} `;
      }
    });
  };

  return (
    <div>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Dan Energy Initiatives</title>
      <style
        dangerouslySetInnerHTML={{
          __html:
            ".acontainer {\n            display: flex;\n            justify-content: space-between;\n                        margin: 15%;\n      margin-top: 5% !important\n    }\n\n        .main-content {\n            flex: 1;\n            background-color: #fff;\n            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n            padding: 20px;\n        }\n\n        .sidebar {\n            flex: 0 0 300px;\n            background-color: #f4f4f4;\n            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n            padding: 20px;\n        }\n\n              h2 {\n            color: #333;\n        }\n\n        p {\n            color: #666;\n        }\n\n        ul {\n            list-style: none;\n            padding: 0;\n            margin: 0;\n        }\n\n        ul li {\n            margin-bottom: 10px;\n        }\n\n        ul li a {\n            text-decoration: none;\n            color: #333;\n    }",
        }}
      />

      <div className="acontainer">
        <div className="main-content">
          <h2>
            <span className=" text-3xl">T</span>
            <span className=" -ml-1">
              echnology and Mindset: Empowering Lives
            </span>
          </h2>

          <p>
            As a talented young software engineer after graduating from Saint
            Mary's University, I became part of the Dan Energy family having
            been a beneficiary of the{" "}
            <a
              href="https://twitter.com/search?q=BridgesEthiopia&src=typed_query"
              target="_blank"
            >
              @BridgesEthiopia
            </a>{" "}
            program of{" "}
            <a
              href="https://twitter.com/search?q=DanEnergyET&src=typed_query&f=top"
              target="_blank"
            >
              @DanEnergyET
            </a>{" "}
            organized in collaboration with{" "}
            <a
              href="https://twitter.com/search?q=FirstConsultEth&src=typed_query&f=top"
              target="_blank"
            >
              @FirstConsultEth
            </a>{" "}
            and{" "}
            <a
              href="https://twitter.com/search?q=MastercardFdn&src=typed_query&f=top"
              target="_blank"
            >
              @MastercardFdn
            </a>
            .
          </p>

          <p>
            Greetings! I am Menal Ibrahim and I am excited to share the
            remarkable progress of these initiatives, which are significantly
            influencing the aspirations and futures of determined young
            individuals.
          </p>

          <div className="my-10 flex justify-center">
            <img
              src="https://res.cloudinary.com/ddyvv0new/image/upload/v1702890952/Article/WhatsApp_Image_2023-12-18_at_12.14.42_71d0474c_xpoxv8.jpg"
              alt="Menal Ibrahim"
            />
          </div>

          <p>
            The comprehensive training session covered during the program
            includes a range of essential skills, focusing on developing not
            only soft and technical skills but also entrepreneurship and the 3H
            approach (Head, Hand & Heart), alongside ABC image management and
            office etiquette.
          </p>

          <p>
            After completing the program, I was able to secure a role as a
            Software Engineer focusing on web development at Dan Energy working
            from Monday to Friday. The training provided valuable insights and
            practical strategies to enhance vital office skills and build strong
            relationships with colleagues.
          </p>

          <p>
            As a web developer, my role is to exclusively develop websites using
            React and WordPress as my primary technologies. Through developing
            expertise in these frameworks, I have successfully contributed to
            numerous projects.
          </p>

          <h2>WeVolunteer: A step forward to resilient Legacy</h2>

          <p>
            Dan Energy, with its motto “Empowering Life,” has corporate social
            responsibility programs that go beyond simple corporate
            responsibility acts and act more as a fulfillment of a vision to
            build a successful nation.
          </p>

          <div className="my-10 flex justify-center">
            <img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1702892328/Article/WhatsApp_Image_2023-12-18_at_12.38.04_02c0b48f_gtlpzs.jpg" />
          </div>

          <p>
            This initiative termed WeVolunteer gives a chance to the company
            staff, in collaboration with external willing experts, to volunteer
            on nurturing the next generation under diverse programs. Utilizing
            my coding knowledge to volunteer, I share my knowledge with the
            young generations through SatCoding and SundayCoding.
          </p>

          <p>
            Every Saturday morning, Dan Energy continues its endeavor to
            positively impact society, hosting an open discussion stage to
            explore the topics of YOUTH, WORK, and LIFE inviting employees and
            outside guests.
          </p>

          <p>
            Taking into consideration the fact that the youth are the builders
            of tomorrow, on this stage difficult but necessary topics are raised
            with the intention to cultivate a growing and enduring mentality.
          </p>

          <div className="my-10 flex justify-center">
            <img
              src="https://res.cloudinary.com/ddyvv0new/image/upload/v1685528718/DAN%20WEBSITE%20IMAGES/sat%20square/photo_3_2023-05-31_13-25-07_qpyrfg.jpg"
              alt="Open Discussion Stage"
            />
          </div>

          <p>
            Amongst the topics discussed, WILL, DECISION, and ACTION gave me
            significant insight. These points helped me understand my values,
            what I prioritize, my strengths and weaknesses, to set clear and
            specific goals, to research the consequences, take decisive actions,
            and stay persistent despite challenges.
          </p>

          <p>
            I also share similar topics in my teaching lesson for the youth
            attending SatCoding and SundayCoding after toning it down to their
            age level. Through engaging the youth in such thought-provoking
            discussion and sharing volunteers experience with them, we give them
            a chance to learn how to navigate life better and make the best of
            themselves.
          </p>

          <h2>The Youths' Saturday and Sunday</h2>

          <p>
            Our mission in{" "}
            <a
              href="https://twitter.com/hashtag/SatCoding?src=hashtag_click"
              target="_blank"
            >
              #SatCoding
            </a>{" "}
            and SundayCoding is to address the socio-economic barriers and
            create a level playing field for all youth. We firmly believe that
            education should be a right, not a privilege, and everyone should
            have equal access to educational opportunities, even more one that
            specially promotes mindset development and horizon broadening.
          </p>

          <p>
            SatCoding on Saturdays and SundayCoding on Sundays is a platform
            that aims to empower the next generation by providing them with the
            necessary resources and guidance to excel in the field of coding,
            while in adherence to its motto, Dan Energy insists that the youth
            must also examine and nurture their mindset.
          </p>

          <div className="my-10 flex justify-center">
            <img
              src="https://res.cloudinary.com/ddyvv0new/image/upload/v1699884005/sundaycoding/photo_5_2023-11-13_16-58-22_kydwml.jpg"
              alt="SatCoding and SundayCoding"
            />
          </div>

          <p>
            The platform offers free coding lessons every Saturday and Sunday,
            encouraging young individuals to explore and engage in computer
            programming as they also uncover the best way to develop
            disruptive-thinking and collaborative mindset, led by dedicated
            volunteers.
          </p>

          <p>
            As volunteers, we create a supportive and interactive learning
            environment where these youths can learn the fundamentals of coding
            and delve into the complexities of application development. Through
            exposing the youth to computers and coding education early, this
            program intends to broaden their horizon allowing them to set
            realistic goals and nurture a will to chase their vision with
            commitment.
          </p>

          <p>
            This initiative is designed to promote inclusiveness and equality by
            providing access to coding education for children from diverse
            backgrounds, including those who may not have such opportunities
            otherwise.
          </p>

          <h2>Young Girls and the Tech-world</h2>

          <p>
            As a woman instructor at SatCoding and SundayCoding, I have a
            crucial role in empowering young girls to pursue their passion for
            technology. My fellow female instructors and I not only break
            stereotypes but also pave the way for a more inclusive and diverse
            tech industry.
          </p>

          <p>
            By offering guidance, support, and inspiration, these initiatives
            nurture future female leaders who will shape the future of
            technology. Although it may not always be easy, with dedication,
            perseverance, and community support, women can thrive in the tech
            industry.
          </p>

          <p>
            I am grateful to Dan Energy for providing a platform that has
            allowed me to shine in this field and has given me the chance to
            share this opportunity with the next generation.
          </p>
          <div className="my-10 flex justify-center">
            <img
              src="https://res.cloudinary.com/ddyvv0new/image/upload/v1702890952/Article/WhatsApp_Image_2023-12-18_at_12.13.58_f3638c31_sn1caj.jpg"
              alt="Empowering Young Girls in Technology"
            />
          </div>
          <p>
            I have noticed that a higher number of my students are young girls
            who are also the most active participants, asking questions and
            expressing enthusiasm for learning. There is a growing interest in
            coding among young girls, further inspired as they see a proficient
            female role model in coding. This can help them overcome any
            self-doubt about gender and technology, showing them firsthand that
            they too can excel in the tech industry.
          </p>

          <p>
            Being a woman instructor in the coding field has been a rewarding
            and fulfilling experience. Through sharing my knowledge and journey,
            I encourage others to pursue their passion for coding and change
            gender specific generalizations.
          </p>

          <p>
            This has allowed me to create a supportive learning environment that
            encourages collaboration and teamwork. As women instructors, we
            bring a unique perspective and experiences to the table, providing a
            more inclusive and nurturing learning environment for all students.
            This representation helps challenge backward thoughts and
            demonstrates that gender should not be a barrier to pursuing a
            career in tech.
          </p>

          <p>
            Dan Energy welcomes individuals to collaborate with us in various
            capacities, particularly women, to showcase their formidable
            capabilities. Whether it be through mentoring, volunteering as
            teachers, and exploring different support avenues.
          </p>

          <div className="my-10 flex justify-center">
            <img
              src="https://res.cloudinary.com/ddyvv0new/image/upload/v1702890952/Article/satcoding_qbpirw.jpg"
              alt="Collaborate With Dan Energy"
            />
          </div>

          <p>
            By embracing diverse approaches to joining our mission, we can
            collectively enhance our impact and expand our outreach to more
            young minds. Let's work together to create a future where education
            serves as a catalyst for positive and enduring transformation within
            communities.
          </p>

          <p>
            Our journey is continuous, and with your invaluable support, we can
            pave the path for the next generation. We extend a warm invitation
            to all those who are interested in exploring our programs,
            witnessing the profound positive impact firsthand, and joining us on
            this transformative journey.
          </p>

          <p>
            Let us come together and inspire the continuation of legacies,
            fostering a timeless transformation that reaches far beyond the
            foreseeable future. We aim to make a significant and everlasting
            historical remark.
          </p>
          <div className="my-10 flex justify-center">
            <img
              src="https://res.cloudinary.com/ddyvv0new/image/upload/v1702890955/Article/suncoding_duiyvu.jpg"
              alt=""
            />
          </div>
          <p>
            Fueling innovation starts with empowering the youth, nurturing their
            tech prowess, and encouraging female leadership in technology.{" "}
          </p>
          <p>
            Let's inspire and empower the next generation to forge a brighter
            future!
          </p>

          <a
            style={{ display: "flex", justifyContent: "right" }}
            href="mailto:menale.ibrahim@danenergy.com"
          >
            menale.ibrahim@danenergy.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default MenalArticle;
