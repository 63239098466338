import React from 'react';
import './components/styles.css';
import { useState } from 'react';
import Hero from './components/Hero.js';

const CarePad = () => {
  const images = [
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534501/DAN%20WEBSITE%20IMAGES/care%20pad/photo_17_2023-05-31_15-01-24_cynfkk.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534501/DAN%20WEBSITE%20IMAGES/care%20pad/photo_20_2023-05-31_15-01-24_ifr1wj.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534501/DAN%20WEBSITE%20IMAGES/care%20pad/photo_18_2023-05-31_15-01-24_nrqcdc.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534501/DAN%20WEBSITE%20IMAGES/care%20pad/photo_19_2023-05-31_15-01-24_mxkvmk.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534500/DAN%20WEBSITE%20IMAGES/care%20pad/photo_15_2023-05-31_15-01-24_e3uekh.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534500/DAN%20WEBSITE%20IMAGES/care%20pad/photo_14_2023-05-31_15-01-24_mwpo4f.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534500/DAN%20WEBSITE%20IMAGES/care%20pad/photo_16_2023-05-31_15-01-24_wlgx9p.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534499/DAN%20WEBSITE%20IMAGES/care%20pad/photo_10_2023-05-31_15-01-24_qqigln.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534499/DAN%20WEBSITE%20IMAGES/care%20pad/photo_11_2023-05-31_15-01-24_vhfl5i.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534500/DAN%20WEBSITE%20IMAGES/care%20pad/photo_8_2023-05-31_15-01-24_nauooe.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534499/DAN%20WEBSITE%20IMAGES/care%20pad/photo_13_2023-05-31_15-01-24_lciaep.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534499/DAN%20WEBSITE%20IMAGES/care%20pad/photo_12_2023-05-31_15-01-24_w2topx.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534499/DAN%20WEBSITE%20IMAGES/care%20pad/photo_9_2023-05-31_15-01-24_dobwrs.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534498/DAN%20WEBSITE%20IMAGES/care%20pad/photo_4_2023-05-31_15-01-24_brqtts.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534497/DAN%20WEBSITE%20IMAGES/care%20pad/photo_1_2023-05-31_15-01-24_iponfw.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534498/DAN%20WEBSITE%20IMAGES/care%20pad/photo_3_2023-05-31_15-01-24_k0xgik.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534498/DAN%20WEBSITE%20IMAGES/care%20pad/photo_2_2023-05-31_15-01-24_izqxjo.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534498/DAN%20WEBSITE%20IMAGES/care%20pad/photo_6_2023-05-31_15-01-24_qkfntt.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685534498/DAN%20WEBSITE%20IMAGES/care%20pad/photo_5_2023-05-31_15-01-24_m946cq.jpg',
    
    
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      <div style={{paddingTop:"100px",paddingBottom:"25px"}}><Hero/></div>
      <div className="image-grid" >
        {images.map((image, index) => (
          <div key={index} className="image-card" >
            <img
              src={image}
              alt={`Image ${index + 1}`}
              onClick={() => handleClick(image)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="modal">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <img src={selectedImage} alt="Full Image" />
        </div>
      )}
    </div>
  );
};

export default CarePad;
