import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

const data = [
  { name: 'Instructors (Female)', value: 10 },
  { name: 'Instructors (Male)', value: 48 },
  { name: 'Students (Female 10-18)', value: 1214 },
  { name: 'Students (Male 10-18)', value: 1608 },
  { name: 'Students (Female 18-24)', value: 208 },
  { name: 'Students (Male 18-24)', value: 497 },
];

const COLORS = ['#222C65', '#70E304', '#0088FE', '#222C65', '', '#FF195B', '#0088FE'];

export default class SatCodingCounter extends PureComponent {
  render() {
    return (
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', padding: '20px' }}>
        <div>
          <h6>Instructors</h6>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data.filter(entry => entry.name.includes('Instructors'))}
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div>
          <h6>Students (10-18)</h6>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data.filter(entry => entry.name.includes('Students (Female 10-18)') || entry.name.includes('Students (Male 10-18)'))}
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div>
          <h6>Students (18-24)</h6>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data.filter(entry => entry.name.includes('Students (Female 18-24)') || entry.name.includes('Students (Male 18-24)'))}
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div>
          <h6>All Groups</h6>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data}
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}
