import AllRoutes from "./routes/AllRoutes";
import ScrollToTop from "./utilities/functions/ScrollToTop";
import { PropagateLoader } from "react-spinners";
import { Center } from "@chakra-ui/react";
import { Suspense } from "react";

function App() {
  let routes = <AllRoutes />;

  return (
    <div className="App">
      <ScrollToTop />
      <Suspense
        fallback={
          <Center w="100%" h="70vh">
            <PropagateLoader size="1rem" color="#70E304" />
          </Center>
        }
      >
        {routes}
      </Suspense>
    </div>
  );
}

export default App;
