import React from "react";

const ZaidArticle = () => {
  return (
    <div>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Dan Energy Initiatives</title>

      <div className=" flex justify-center">
        <div className="main-content max-w-6xl  flex flex-col items-center justify-center text-center">
          <h2 className="text-2xl md:text-3xl lg:text-4xl my-8 ">
            Impacting Life: Fashion Design and Livelihood Enabling
          </h2>

          <div className=" grid grid-cols-1 md:grid-cols-2 my-6">
            <div className=" flex flex-col justify-center items-center gap-4 mx-4">
              <p>
                Greetings! I am Zaid Tesfaye, here to tell you of my experience
                of fashion design and the impact it can have on the life of ours
                and those around us. It is an amazing experience to know and
                walk the path where your passion, dream and talent are aligned.
                I graduated in accounting for my first degree, yet my interest
                lied in fashion design. Therefore, I spent a year learning in
                clothing designs and relevant skills in a fashion design
                college.
              </p>

              <p>
                To me, fashion design is more than just about looking
                stylish—it's a way to express who I am. As a fashion designer,
                my goal is to create experiences, tell stories, and contribute
                to the vibrant world of fashion. I don't want to simply make
                clothes; I want each piece to have a meaningful background. I
                strive to create designs that touch people on a deeper level
                beyond just how they look, leaving a lasting impact.
              </p>
            </div>

            <div className=" p-8">
              <img
                src="https://res.cloudinary.com/ddyvv0new/image/upload/v1706101451/Article/IMG-20240124-WA0003_hfgapv.jpg"
                alt="Zaid Tesfaye"
              />
            </div>
          </div>

          <div className=" grid grid-cols-1 md:grid-cols-2 my-6">
            <div className=" p-8">
              <div className="my-10 flex justify-center">
                <img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1706101451/Article/IMG-20240124-WA0005_wlxwxg.jpg" />
              </div>
            </div>

            <div className=" flex flex-col justify-center items-center gap-4 mx-4">
              <p>
                However, the focus of my narrative is after I started working at
                Dan Energy, a research, development and deployment company. With
                over 42 strategic business units (SBUs) dedicated to various
                technology industries, Dan Energy embodies the spirit of
                innovation and seeks to pave the way as the next generation of
                solution providers. Working within this dynamic organization has
                ignited my drive to make a meaningful impact and be part of
                shaping the future.
              </p>

              <p>
                I entered the company relying on my accounting knowledge, yet
                due to the company’s valuable talent hunt department and carrier
                decision initiative I was able to truly Identify my passion and
                direction of carrier. The company Provided a soft and technical
                skill training which allowed me to experience many vital
                workplace lessons. Through this opportunities I was able to
                broaden my horizons and also find an outlet for my passion by
                participating on one of Dan Energy’s initiatives following its
                motto “Empowering Life”. This initiative is called Single-Moms
                and I believe it is a project with a far reaching impact.
              </p>
            </div>
          </div>

          <div>
            <div className="my-10 flex justify-center">
              <img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1706101451/Article/IMG-20240124-WA0004_pa6dl6.jpg" />
            </div>

            <div className=" flex flex-col gap-4 mx-4">
              <p>
                Single-Moms, one of the initiatives of Dan Energy in its
                endeavor to empower life, is targeted at creating improvement to
                the livelihood of Single Mothers, whom have for different reason
                are facing the world alone for their children and themselves.
                This strong women toll day and night to raise their children
                enduring various challenges. Dan Energy is of the mind, given
                some support these women can achieve more, hence it started this
                initiative where these women are given training in regards to
                garment industry and how to design clothing.
              </p>

              <p>
                This program takes 20 women per month , dedicated to actively
                empower single mothers by providing valuable training in garment
                design, product development, and essential industry skills. This
                momentous program aims to equip these resilient individuals with
                the knowledge and tools needed to establish their own
                businesses. This training includes lessons and discussions to
                help them transform their mentality positively obtaining growth
                following the 3H “Head Heart Hand” principle of the company.
              </p>
            </div>
          </div>

          <div className=" grid grid-cols-1 md:grid-cols-2 my-6">
            <div className=" flex flex-col justify-center items-center gap-4 mx-4">
              <p>
                Playing a pivotal role in this initiative, I serve as the mentor
                and trainer for these inspiring women. My focus encompasses
                imparting valuable insights into fashion designing, patterns,
                and every aspect related to this creative field. The aim of this
                endeavor is to enable single mothers to transform their
                situation by translating the gained skills into financial
                success and a better livelihood. As these single mothers acquire
                the skills and develop entrepreneurial mindset, they not only
                contribute to the economic landscape but also experience
                personal and professional growth.
              </p>

              <p>
                This commitment is not merely about transferring technical
                skills; it's about fostering creativity, confidence, and
                empowering these resilient women to become architects of their
                own destinies. Every stitch we sew together tells a story of
                resilience, determination, and the pursuit of dreams. Through
                this voluntary initiative, I hope to inspire positive change
                within communities, demonstrating the reforming power of fashion
                as a catalyst for empowerment and self-discovery.
              </p>
            </div>

            <div className=" p-8">
              <div className="my-10 flex justify-center">
                <img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1706101452/Article/IMG-20240124-WA0002_efypmu.jpg" />
              </div>
            </div>
          </div>

          <div className=" grid grid-cols-1 md:grid-cols-2 my-6">
            <div className="p-8">
              <div className="my-10 flex justify-center">
                <img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1706101452/Article/IMG-20240124-WA0001_tookmp.jpg" />
              </div>
            </div>

            <div className=" flex flex-col justify-center items-center gap-4 mx-4">
              <p>
                Dan Energy's mission is to create a brighter future for single
                mothers, one stitch at a time. By investing in their
                entrepreneurial spirit and creative talent, we pave the way for
                not just individual success but also a more vibrant and
                inclusive fashion landscape. Join us in being a part of
                rewriting the narrative, one empowered woman at a time.
              </p>

              <p>
                Together, let's weave dreams into reality, one garment at a
                time.
              </p>
            </div>
          </div>

          <div className=" w-full flex justify-end text-3xl ">
            <a href="mailto:Zaid.tesfaye@danenergyethiopia.com">
              Zaid.tesfaye@danenergyethiopia.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZaidArticle;
