import React from 'react';
import './components/styles.css';
import { useState } from 'react';
import Hero from './components/Hero.js';

const SatMent = () => {
  const images = [
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885453/satment/photo_2_2023-11-13_17-23-24_lefsz9.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885453/satment/photo_1_2023-11-13_17-23-24_hqahhm.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885453/satment/photo_3_2023-11-13_17-23-24_ols9aw.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885454/satment/photo_4_2023-11-13_17-23-24_l1gv7g.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885455/satment/photo_9_2023-11-13_17-23-24_btyaot.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885456/satment/photo_7_2023-11-13_17-23-24_youdaj.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885457/satment/photo_6_2023-11-13_17-23-24_xpv9dy.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885458/satment/photo_11_2023-11-13_17-23-24_zd2es5.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885459/satment/photo_12_2023-11-13_17-23-24_xeliko.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885460/satment/photo_8_2023-11-13_17-23-24_esp4fg.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885461/satment/photo_5_2023-11-13_17-23-24_yqoam0.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699885463/satment/photo_10_2023-11-13_17-23-24_wgvrcb.jpg',


  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      <div style={{paddingTop:"100px",paddingBottom:"25px"}}><Hero/></div>
      <p>Dan Energy introduces SatMent, a platform tailored for students aged 14-18 seeking academic support in Maths, Physics, and English. As part of our ongoing youth empowerment initiative, we have created this mentorship program to assist students in achieving excellence. Our devoted volunteers, who allocate their time every Saturday afternoon, take on the responsibility of sharing their knowledge as seniors, thereby enhancing the students' understanding of these subjects.</p>
      <div className="image-grid" >
        {images.map((image, index) => (
          <div key={index} className="image-card" >
            <img
              src={image}
              alt={`Image ${index + 1}`}
              onClick={() => handleClick(image)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="modal">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <img src={selectedImage} alt="Full Image" />
        </div>
      )}
    </div>
  );
};

export default SatMent;
