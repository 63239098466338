import { Box, Button, Heading, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";

const Hero = () => {
  return (
    <Box
    bgAttachment={"fixed"}
      bgImage={
        "https://rare-gallery.com/uploads/posts/580031-blog-blogger.jpg"
      }
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize={{ base: "cover", "2xl": "cover" }}
      minH="50vh"
      color="black"
    >
      <VStack
        pl="5%"
        justify="center"
        align="center"
        pt="25vh"
        textColor={"white"}
      >
        <Heading fontSize={"50px"} textAlign={"center"}>
          Blogs & Articles
        </Heading>
        
      </VStack>
    </Box>
  );
};

export default Hero;
