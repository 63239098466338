import React from 'react';
import './components/styles.css';
import { useState } from 'react';
import Hero from './components/Hero.js';

const SatSquare = () => {
  const images = [
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1687187209/DAN%20WEBSITE%20IMAGES/sat%20square/DSC04184_gfm4ys.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1687187207/DAN%20WEBSITE%20IMAGES/sat%20square/DSC04124_ychntk.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1687187192/DAN%20WEBSITE%20IMAGES/sat%20square/photo_2023-06-19_18-00-57_pgqfkr.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1687187192/DAN%20WEBSITE%20IMAGES/sat%20square/photo_2023-06-19_18-00-59_pimqvg.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1687187192/DAN%20WEBSITE%20IMAGES/sat%20square/photo_2023-06-19_18-01-01_c3qxka.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1687187191/DAN%20WEBSITE%20IMAGES/sat%20square/photo_2023-06-19_18-00-58_kky2vf.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528720/DAN%20WEBSITE%20IMAGES/sat%20square/photo_17_2023-05-31_13-25-07_iaclho.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528720/DAN%20WEBSITE%20IMAGES/sat%20square/photo_13_2023-05-31_13-25-07_qjauyk.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528720/DAN%20WEBSITE%20IMAGES/sat%20square/photo_11_2023-05-31_13-25-07_j8svzi.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528720/DAN%20WEBSITE%20IMAGES/sat%20square/photo_15_2023-05-31_13-25-07_bmqlvg.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528720/DAN%20WEBSITE%20IMAGES/sat%20square/photo_16_2023-05-31_13-25-07_pe42pu.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528720/DAN%20WEBSITE%20IMAGES/sat%20square/photo_12_2023-05-31_13-25-07_jsy28i.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528720/DAN%20WEBSITE%20IMAGES/sat%20square/photo_14_2023-05-31_13-25-07_y12pjz.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528719/DAN%20WEBSITE%20IMAGES/sat%20square/photo_9_2023-05-31_13-25-07_gadpfs.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528719/DAN%20WEBSITE%20IMAGES/sat%20square/photo_10_2023-05-31_13-25-07_tnsqum.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528719/DAN%20WEBSITE%20IMAGES/sat%20square/photo_8_2023-05-31_13-25-07_gs1rw6.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528719/DAN%20WEBSITE%20IMAGES/sat%20square/photo_7_2023-05-31_13-25-07_gjgq73.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528719/DAN%20WEBSITE%20IMAGES/sat%20square/photo_6_2023-05-31_13-25-07_du07cq.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528719/DAN%20WEBSITE%20IMAGES/sat%20square/photo_5_2023-05-31_13-25-07_ist12j.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528718/DAN%20WEBSITE%20IMAGES/sat%20square/photo_4_2023-05-31_13-25-07_jhkwhg.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528718/DAN%20WEBSITE%20IMAGES/sat%20square/photo_3_2023-05-31_13-25-07_qpyrfg.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528718/DAN%20WEBSITE%20IMAGES/sat%20square/photo_1_2023-05-31_13-25-07_mnpaq1.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1685528718/DAN%20WEBSITE%20IMAGES/sat%20square/photo_2_2023-05-31_13-25-07_i3ebud.jpg'
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      <div style={{paddingTop:"100px",paddingBottom:"25px"}}><Hero/></div>
      <p>SatSquare is a semi-formal open discussion stage setup to explore the topics YOUTH, WORK and LIFE. Dan Energy is committed to work on the youth as they are by which the future is built and through them the coming generation can obtain abundant positive legacies. Therefore, the company invites the youth and provides them a stage where they can obtain insights on life from those who have gone through many obstacles and obtained achievements. On this stage the youth can listen to the wisdom of the experienced and openly discuss questions that will lead them towards a positive mindset shift.</p>
      <div className="image-grid">
        {images.map((image, index) => (
          <div key={index} className="image-card">
            <img
              src={image}
              alt={`Image ${index + 1}`}
              onClick={() => handleClick(image)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="modal">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <img src={selectedImage} alt="Full Image" />
        </div>
      )}
    </div>
  );
};

export default SatSquare;
