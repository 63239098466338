import React from "react";
import "./components/styles.css";
import { useState } from "react";
import Hero from "./components/Hero.js";
import SatCodingCounter from "./components/counter/satcodingcounter.js";

const SatCoding = () => {
  const mediaItems = [

    {
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1687187771/DAN%20WEBSITE%20IMAGES/sat%20coding/photo_2023-06-19_18-00-52_vhaa97.jpg",
    },
    {
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1687187771/DAN%20WEBSITE%20IMAGES/sat%20coding/photo_2023-06-19_18-00-54_rfou4j.jpg",
    },
    {
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1687187771/DAN%20WEBSITE%20IMAGES/sat%20coding/photo_2023-06-19_18-00-56_lmzkog.jpg",
    },
    {
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1687187770/DAN%20WEBSITE%20IMAGES/sat%20coding/photo_2023-06-19_18-00-53_g62mkh.jpg",
    },



    {
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1685532352/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0019_gfmhaw.jpg",
    },
    {
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1686032651/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230606-WA0002_kqeskw.jpg",
    },
    
    
    {
      type: "video",
      url: "https://res.cloudinary.com/ddyvv0new/video/upload/v1686032652/DAN%20WEBSITE%20IMAGES/sat%20coding/VID-20230606-WA0002_ltpndp.mp4",
    },
    
    {
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1685532352/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0022_wdqb0r.jpg",
    },
    
    {
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1685532349/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0008_bwurr6.jpg",
    },
    {
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1685532352/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0023_bl7xoa.jpg",
    },{
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1685532349/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0003_tkqwod.jpg",
    },
    {
      type: "video",
      url: "https://res.cloudinary.com/ddyvv0new/video/upload/v1686032652/DAN%20WEBSITE%20IMAGES/sat%20coding/VID-20230606-WA0001_x0zepw.mp4",
    },
    {
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1685532350/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0002_pexbgt.jpg",
    },
    {
      type: "image",
      url: "https://res.cloudinary.com/ddyvv0new/image/upload/v1685532349/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0004_ljked5.jpg",
    },
    // 'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532352/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0019_gfmhaw.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532352/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0023_bl7xoa.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532352/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0005_jnshnc.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532352/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0017_fklgby.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532352/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0022_wdqb0r.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532351/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0016_sinry4.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532352/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0022_wdqb0r.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532351/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0016_sinry4.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532351/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0021_biizpg.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532351/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0020_opeubz.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532351/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0001_qieuim.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532351/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0014_djp3zw.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532350/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0010_vfjutf.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532350/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0013_wuctob.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532350/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0009_ivxaaz.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532350/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0002_pexbgt.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532350/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0012_ay43ra.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532350/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0011_gesbdc.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532349/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0008_bwurr6.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532349/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0006_zzmkei.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532349/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0004_ljked5.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532349/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0007_ncndbh.jpg',
    //   'https://res.cloudinary.com/ddyvv0new/image/upload/v1685532349/DAN%20WEBSITE%20IMAGES/sat%20coding/IMG-20230531-WA0003_tkqwod.jpg',
  ];

  const [selectedMedia, setSelectedMedia] = useState(null);

  const handleClick = (media) => {
    setSelectedMedia(media);
  };

  const handleClose = () => {
    setSelectedMedia(null);
  };

  return (
    <div className="gallery-container">
      <div style={{ paddingTop: "100px", paddingBottom: "25px" }}>
        <Hero />
        <SatCodingCounter/>
      </div>
      <p>SatCoding, powered by Dan Energy, is a platform tailored to broaden the horizons of the youth. Through the dedication of volunteers, coding lessons are conducted every Saturday afternoon. Within this program, individuals aged 14-18 are exposed to the world of computers and the enigma behind application development through coding education. In essence, this platform aims to equip the youth with the skills and knowledge needed to lead in the digital technology era.</p>
      <div className="image-grid">
        {mediaItems.map((media, index) => (
          <div key={index} className="image-card">
            {media.type === "image" ? (
              <img
                src={media.url}
                alt={`Image ${index + 1}`}
                onClick={() => handleClick(media)}
              />
            ) : (
              <video
              className="vid"
              
                src={media.url}
                alt={`Video ${index + 1}`}
                onClick={() => handleClick(media)}
                controls
              />
            )}
          </div>
        ))}
      </div>
      {selectedMedia && (
        <div className="modal">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          {selectedMedia.type === "image" ? (
            <img src={selectedMedia.url} alt="Full Image" />
          ) : (
            <video
              
              
              src={selectedMedia.url}
              alt="Full Video"
              controls
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SatCoding;
