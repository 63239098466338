import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Stack,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import React from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { navLinks } from "../Data";
import Footer from "./Footer";
import Logo from "./Logo";
import { GradientButton, MainNav } from "./MainNavigation.elmenets";
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import Scroll from "./Scroll";
import { BiChevronDown } from "react-icons/bi";
import "../../blog/style.css";

const MainNavigation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const applyLink =
    "https://docs.google.com/forms/d/e/1FAIpQLSeFhAJ3C_6sPr_vYhNp3t3u8IbYw-wHNHMv3xnWf0D2029N6A/viewform?usp=sf_link";
  console.log();

  return (
    <Stack spacing={"0px"}>
      <MainNav>
        <>
          <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <Stack
                py="4%"
                display={{ base: "flex", md: "none" }}
                direction={{ base: "column", md: "row" }}
                spacing={"25px"}
                justify="center"
                align={"center"}
              >
                <Logo />
                {navLinks.map((link, index) => {
                  if (link.name === "CSR") {
                    return (
                      <div className="itpark desk">
                        <a
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "auto",
                          }}
                          // href="/corporate-social-responsibility"
                        >
                          CSR <BiChevronDown />
                        </a>
                        <span className="dropdown">
                          <NavLink to={"/corporate-social-responsibility"}>
                            CSR
                          </NavLink>
                          <NavLink to={"/dan_foundation"}>
                            Dan Foundation
                          </NavLink>
                        </span>
                      </div>
                    );
                  }
                  if (link.name === "Dan Services") {
                    return (
                      <div className="itpark desk">
                        <a
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "auto",
                            cursor: "pointer",
                          }}
                          // href="/"
                        >
                          Dan Services <BiChevronDown />
                        </a>
                        <span className="dropdown">
                          <NavLink to={"/animation"}>
                            Animation & Gaming
                          </NavLink>
                          <NavLink to={"/cloud"}>Cloud Computing</NavLink>
                          <NavLink to={"/erpcrm"}>CRM</NavLink>
                          <NavLink to={"/cybersecurity"}>
                            Cyber Security
                          </NavLink>
                          <NavLink to={"/dandmpo"}>Data Management</NavLink>
                          <NavLink to={"/digitalmarketing"}>
                            Digital Marketing
                          </NavLink>
                          <NavLink to={"/erp"}>ERP</NavLink>
                          <NavLink to={"/finance"}>
                            Finance & Accounting
                          </NavLink>
                        </span>
                      </div>
                    );
                  }
                  if (link.name === "Dan Technologies") {
                    return (
                      <div className="itpark desk">
                        <a
                          style={{
                            display: "flex",
                            alignItems: "center",
                            margin: "auto",
                            cursor: "pointer",
                          }}
                          // href="/"
                        >
                          Dan Technologies <BiChevronDown />
                        </a>
                        <span className="dropdown">
                          <NavLink to={"/agritech"}>Agri Tech Labs</NavLink>
                          <NavLink to={"/artificial_intelligence"}>
                            AI Labs
                          </NavLink>
                          <NavLink to={"/elearning"}>Edu Tech Labs</NavLink>
                          <NavLink to={"/fin_tech"}>Fin Tech Labs</NavLink>
                          <NavLink to={"/services"}>Health Tech Labs</NavLink>
                          <NavLink to={"/research_and_development"}>
                            R&D Labs
                          </NavLink>
                          <NavLink to={"/security_labs"}>Security Labs</NavLink>
                          <NavLink to={"/training_and_placement"}>
                            Training & Placement
                          </NavLink>
                        </span>
                      </div>
                    );
                  }
                  if (link.name === "Tecno Park") {
                    if (location.pathname === "/") {
                      return <a href="#itpark">Tecno Park</a>;
                    } else return null;
                  }
                  if (link.name === "Apply") {
                    return (
                      <a target={"_blank"} href={applyLink}>
                        Apply
                      </a>
                    );
                  }

                  return (
                    <NavLink onClick={onClose} to={link.to} key={index}>
                      {link.name}
                    </NavLink>
                  );
                })}
                <Button
                  bg="transparent"
                  fontWeight={"bold"}
                  color="green"
                  _hover={{
                    bg: "green",
                    color: "#FFF",
                  }}
                  onClick={() =>
                    (window.location = "http://www.danenergyglobal.com/")
                  }
                >
                  Dan Global
                </Button>
              </Stack>
            </DrawerContent>
          </Drawer>
          <Wrap
            px={{ base: "3%", md: "1%", lg: "3%", xl: "7%" }}
            align={"center"}
            justify={"space-between"}
            py="4px"
          >
            <Logo />
            <Stack
              display={{ base: "none", md: "flex" }}
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "25px", md: "12px", lg: "15px" }}
              justify="center"
              align={"center"}
              fontSize={{ base: "15px", md: "16px", lg: "18px" }}
              color="gray.700"
            >
              {navLinks.map((link, index) => {
                const { name, nameAm, to, auth } = link;
                if (link.name === "CSR") {
                  return (
                    <div className="itpark desk">
                      <a
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "auto",
                        }}
                        href="/corporate-social-responsibility"
                      >
                        CSR <BiChevronDown />
                      </a>
                      <span className="dropdown">
                        <NavLink to={"/corporate-social-responsibility"}>
                          CSR
                        </NavLink>
                        <NavLink to={"/dan_foundation"}>Dan Foundation</NavLink>
                      </span>
                    </div>
                  );
                }
                if (link.name === "Dan Services") {
                  return (
                    <div className="itpark desk">
                      <a
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "auto",
                          cursor: "pointer",
                        }}
                        // href="/"
                      >
                        Dan Services <BiChevronDown />
                      </a>
                      <span className="dropdown">
                        <NavLink to={"/animation"}>Animation & Gaming</NavLink>
                        <NavLink to={"/cloud"}>Cloud Computing</NavLink>
                        <NavLink to={"/erpcrm"}>CRM</NavLink>
                        <NavLink to={"/cybersecurity"}>Cyber Security</NavLink>
                        <NavLink to={"/dandmpo"}>Data Management</NavLink>
                        <NavLink to={"/digitalmarketing"}>
                          Digital Marketing
                        </NavLink>
                        <NavLink to={"/erp"}>ERP</NavLink>
                        <NavLink to={"/finance"}>Finance & Accounting</NavLink>
                      </span>
                    </div>
                  );
                }
                if (link.name === "Dan Technologies") {
                  return (
                    <div className="itpark desk">
                      <a
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "auto",
                          cursor: "pointer",
                        }}
                        // href="/"
                      >
                        Dan Technologies <BiChevronDown />
                      </a>
                      <span className="dropdown">
                        <NavLink to={"/agritech"}>Agri Tech Labs</NavLink>
                        <NavLink to={"/artificial_intelligence"}>
                          AI Labs
                        </NavLink>
                        <NavLink to={"/elearning"}>Edu Tech Labs</NavLink>
                        <NavLink to={"/fin_tech"}>Fin Tech Labs</NavLink>
                        <NavLink to={"/services"}>Health Tech Labs</NavLink>
                        <NavLink to={"/research_and_development"}>
                          R&D Labs
                        </NavLink>
                        <NavLink to={"/security_labs"}>Security Labs</NavLink>
                        <NavLink to={"/training_and_placement"}>
                          Training & Placement
                        </NavLink>
                      </span>
                    </div>
                  );
                }
                if (link.name === "Tecno Park") {
                  if (location.pathname === "/") {
                    return <a href="#itpark">Tecno Park</a>;
                  } else return null;
                }
                if (link.name === "Apply") {
                  return (
                    <a target={"_blank"} href={applyLink}>
                      Apply
                    </a>
                  );
                }

                return (
                  <NavLink
                    key={index}
                    style={({ isActive }) => ({
                      padding: 5,
                      //   color: isActive ? "#0079d3" : "",
                      //   fontWeight: isActive ? "bold" : "normal",
                    })}
                    to={to}
                  >
                    {name}
                  </NavLink>
                );
              })}
              <Button
                bg="transparent"
                fontWeight={"bold"}
                color="green"
                _hover={{
                  bg: "green",
                  color: "#FFF",
                }}
                onClick={() =>
                  (window.location = "http://www.danenergyglobal.com/")
                }
              >
                Dan Global
              </Button>
            </Stack>

            <Button
              display={{ base: "flex", md: "none" }}
              bg="transparent"
              onClick={onOpen}
            >
              <GiHamburgerMenu size="1.7rem" />
            </Button>
          </Wrap>
        </>
      </MainNav>
      <Outlet />
      <Scroll />
      <Footer />
    </Stack>
  );
};

export default MainNavigation;
