import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  HStack,
  Center,
  StackDivider,
  Heading,
  Button,
} from "@chakra-ui/react";
import { socialMediaIcons } from "../Data";
import Logo from "./Logo";
import { NavLink, useLocation } from "react-router-dom";

const ListHeader = ({ children, className = "" }) => {
  return (
    <Heading className={className} fontSize={"16px"} mb={2}>
      {children}
    </Heading>
  );
};

export default function Footer() {
  const location = useLocation();

  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"100%"}
        px={{ base: "3%", md: "1%", lg: "3%", xl: "7%" }}
        py={10}
      >
        <Stack
          spacing={"25px"}
          divider={<StackDivider borderColor="gray.400" />}
        >
          <SimpleGrid
            templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 1fr 1fr 1fr" }}
            spacing={8}
          >
            <Stack spacing={6}>
              <Box>
                <Logo />
              </Box>
            </Stack>
            <Stack className="footerNavLink" align={"flex-start"}>
              <ListHeader>Dan Services</ListHeader>
              <div>
                <div>
                  <NavLink to={"/animation"}>Animation & Gaming</NavLink>
                  <NavLink to={"/cloud"}>Cloud Computing</NavLink>
                  <NavLink to={"/erpcrm"}>CRM</NavLink>
                  <NavLink to={"/cybersecurity"}>Cyber Security</NavLink>
                  <NavLink to={"/dandmpo"}>Data Management</NavLink>
                  <NavLink to={"/digitalmarketing"}>Digital Marketing</NavLink>
                </div>
                <div>
                  <NavLink to={"/erp"}>ERP</NavLink>
                  <NavLink to={"/finance"}>Finance & Accounting</NavLink>
                  <NavLink style={{ display: "flex", alignItems: "flex-start", padding:"0px", margin:"0px" }} to={"/sat_square"} className="nav-link">
                    <span >Sat</span>
                    <span style={{display:"flex", alignItems:"flex-start"}}>
                    <img
                    style={{height:"10px"}}
                      src="https://res.cloudinary.com/ddyvv0new/image/upload/v1685544613/DAN%20WEBSITE%20IMAGES/leaf_ye8vad.png"
                      alt="Leaf"
                      className="image"
                    /></span>
                    <span style={{marginLeft:"2px"}} className="text">Square</span>
                  </NavLink>
                  <NavLink style={{ display: "flex", alignItems: "flex-start", padding:"0px", margin:"0px" }} to={"/sat_coding"} className="nav-link">
                    <span >Sat</span>
                    <span style={{display:"flex", alignItems:"flex-start"}}>
                    <img
                    style={{height:"10px"}}
                      src="https://res.cloudinary.com/ddyvv0new/image/upload/v1685544613/DAN%20WEBSITE%20IMAGES/leaf_ye8vad.png"
                      alt="Leaf"
                      className="image"
                    /></span>
                    <span style={{marginLeft:"2px"}} className="text">Coding</span>
                  </NavLink>
                  <NavLink style={{ display: "flex", alignItems: "flex-start", padding:"0px", margin:"0px" }} to={"/ideasmart"} className="nav-link">
                    <span >IdeasMart</span>
                    <span style={{display:"flex", alignItems:"flex-start"}}>
                    <img
                    style={{height:"10px"}}
                      src="https://res.cloudinary.com/ddyvv0new/image/upload/v1685544613/DAN%20WEBSITE%20IMAGES/leaf_ye8vad.png"
                      alt="Leaf"
                      className="image"
                    /></span>
                    
                  </NavLink>
                  <NavLink style={{ display: "flex", alignItems: "flex-start", padding:"0px", margin:"0px" }} to={"/sundaycoding"} className="nav-link">
                    <span >SundayCoding</span>
                    <span style={{display:"flex", alignItems:"flex-start"}}>
                    <img
                    style={{height:"10px"}}
                      src="https://res.cloudinary.com/ddyvv0new/image/upload/v1685544613/DAN%20WEBSITE%20IMAGES/leaf_ye8vad.png"
                      alt="Leaf"
                      className="image"
                    /></span>
                    
                  </NavLink>
                  </div><div>
                  {/* <NavLink style={{ display: "flex", alignItems: "flex-start", padding:"0px", margin:"0px" }} to={"/carepad"} className="nav-link">
                    <span >SundaySquare</span>
                    <span style={{display:"flex", alignItems:"flex-start"}}>
                    <img
                    style={{height:"10px"}}
                      src="https://res.cloudinary.com/ddyvv0new/image/upload/v1685544613/DAN%20WEBSITE%20IMAGES/leaf_ye8vad.png"
                      alt="Leaf"
                      className="image"
                    /></span>
                    
                  </NavLink> */}
                  <NavLink style={{ display: "flex", alignItems: "flex-start", padding:"0px", margin:"0px" }} to={"/satment"} className="nav-link">
                    <span >SatMent</span>
                    <span style={{display:"flex", alignItems:"flex-start"}}>
                    <img
                    style={{height:"10px"}}
                      src="https://res.cloudinary.com/ddyvv0new/image/upload/v1685544613/DAN%20WEBSITE%20IMAGES/leaf_ye8vad.png"
                      alt="Leaf"
                      className="image"
                    /></span>
                    
                  </NavLink>
                  <NavLink style={{ display: "flex", alignItems: "flex-start", padding:"0px", margin:"0px" }} to={"/sundayment"} className="nav-link">
                    <span >SundayMent</span>
                    <span style={{display:"flex", alignItems:"flex-start"}}>
                    <img
                    style={{height:"10px"}}
                      src="https://res.cloudinary.com/ddyvv0new/image/upload/v1685544613/DAN%20WEBSITE%20IMAGES/leaf_ye8vad.png"
                      alt="Leaf"
                      className="image"
                    /></span>
                    
                  </NavLink>
                  <NavLink style={{ display: "flex", alignItems: "flex-start", padding:"0px", margin:"0px" }} to={"/arttech"} className="nav-link">
                    <span >ArtTech</span>
                    <span style={{display:"flex", alignItems:"flex-start"}}>
                    <img
                    style={{height:"10px"}}
                      src="https://res.cloudinary.com/ddyvv0new/image/upload/v1685544613/DAN%20WEBSITE%20IMAGES/leaf_ye8vad.png"
                      alt="Leaf"
                      className="image"
                    /></span>
                    
                  </NavLink>
                  <NavLink style={{ display: "flex", alignItems: "flex-start", padding:"0px", margin:"0px" }} to={"/carepad"} className="nav-link">
                    <span >Care</span>
                    <span style={{display:"flex", alignItems:"flex-start"}}>
                    <img
                    style={{height:"10px"}}
                      src="https://res.cloudinary.com/ddyvv0new/image/upload/v1685544613/DAN%20WEBSITE%20IMAGES/leaf_ye8vad.png"
                      alt="Leaf"
                      className="image"
                    /></span>
                    
                  </NavLink></div>
              </div>
            </Stack>

            <Stack className="footerNavLink" align={"flex-start"}>
              <ListHeader>Dan Technologies </ListHeader>
              <div>
                <div>
                  <NavLink to={"/agritech"}>Agri Tech Labs</NavLink>
                  <NavLink to={"/artificial_intelligence"}> AI Labs </NavLink>
                  <NavLink to={"/elearning"}>Edu Tech Labs</NavLink>
                  <NavLink to={"/fin_tech"}>Fin Tech Labs</NavLink>
                  <NavLink to={"/health_tech"}>Health Tech Labs</NavLink>
                </div>
                <div>
                  <NavLink to={"/research_and_development"}>R&D Labs</NavLink>
                  <NavLink to={"/security_labs"}>Security Labs</NavLink>
                  <NavLink to={"/training_and_placement"}>
                    Training & Placement
                  </NavLink>
                </div>
              </div>
            </Stack>

            <Stack className="footerNavLink" align={"flex-start"}>
              <ListHeader className="korefat">Other Sites</ListHeader>
              <NavLink to={"/corporate-social-responsibility"}>CSR</NavLink>
              <NavLink to={"http://www.danenergyglobal.com/"}>
                Dan Energy Global
              </NavLink>
              <NavLink to={"/dan_foundation"}>Dan Foundation</NavLink>
              <NavLink to={"/ventureCapital"}>Dan Venture Capital</NavLink>
              <NavLink>
                <Button
                  size={"xss"}
                  fontWeight={"light"}
                  color={"#70e304"}
                  onClick={() =>
                    (window.location = "https://www.eagletsfuture.com/")
                  }
                >
                  Eaglets Future
                </Button>
              </NavLink>
            </Stack>

            <Stack className="footerNavLink" align={"flex-start"}>
              <ListHeader className="korefat">Company</ListHeader>
              <NavLink to={"/about"}>About Us</NavLink>
              <NavLink to={"/"}>Careers at Dan</NavLink>
              <NavLink to={"/clientandoperationsprotocol"}>
                Client & Operations Protocol
              </NavLink>
              <NavLink to={"/contact"}>Contact Us</NavLink>
              <NavLink to={"/privacypolicy"}>Privacy Policies</NavLink>
              <NavLink to={"/"}>Site Map</NavLink>
            </Stack>

            <Stack className="footerNavLink" align={"flex-start"}></Stack>
          </SimpleGrid>
          <Center>
            <HStack
              style={{ flexWrap: "wrap", justifyContent: "center" }}
              align={"center"}
              spacing="22px"
            >
              {socialMediaIcons.map((social) => (
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open(social.to, "_blank")}
                >
                  <Box>{social.icon}</Box>
                </span>
              ))}
              <Text
                style={{
                  width: "100%",
                  textAlign: "center",
                  justifyContent: "center",
                  margin: "0",
                  paddingTop: "10px",
                }}
                fontSize={"sm"}
              >
                © {new Date().getFullYear()} Dan Energy. All rights reserved
              </Text>
            </HStack>
          </Center>
        </Stack>
      </Container>
    </Box>
  );
}
