import React from 'react';
import './components/styles.css';
import { useState } from 'react';
import Hero from './components/Hero.js';

const ArtTech = () => {
  const images = [
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886787/arttech/photo_6_2023-11-13_17-45-23_vlkffr.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886786/arttech/photo_5_2023-11-13_17-45-23_qycpcp.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886785/arttech/photo_4_2023-11-13_17-45-23_u5hxkr.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886768/arttech/photo_1_2023-11-13_17-45-23_y8mtvi.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886767/arttech/photo_3_2023-11-13_17-45-23_r2ru9f.jpg',
    'https://res.cloudinary.com/ddyvv0new/image/upload/v1699886767/arttech/photo_2_2023-11-13_17-45-23_t12hdq.jpg',
    
  ];

  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div className="gallery-container">
      <div style={{paddingTop:"100px",paddingBottom:"25px"}}><Hero/></div>
      <div className="image-grid" >
        {images.map((image, index) => (
          <div key={index} className="image-card" >
            <img
              src={image}
              alt={`Image ${index + 1}`}
              onClick={() => handleClick(image)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className="modal">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <img src={selectedImage} alt="Full Image" />
        </div>
      )}
    </div>
  );
};

export default ArtTech;
