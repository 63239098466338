import { Box, Button, Heading, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";

const Hero = () => {
  return (
    <Box
      bgImage={
        "https://res.cloudinary.com/ddyvv0new/image/upload/v1699884542/sundaycoding/sunday_coding_-_white_kskg4r.png"
      }
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize={{ base: "contain", "2xl": "contain" }}
      minH="50vh"
      color="black"
    >
      <VStack
        pl="5%"
        justify="center"
        align="center"
        pt="25vh"
        textColor={"black"}
      >
        {/* <Heading fontSize={"50px"} align="center">
          Sat Square
        </Heading> */}
        
      </VStack>
    </Box>
  );
};

export default Hero;
