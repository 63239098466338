import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";

export const socialMediaIcons = [
  {
    icon: <BsInstagram size="1.5rem" color="#bc2a8d" />,
    to: "https://instagram.com/dan_energy_ethiopia?igshid=YmMyMTA2M2Y=",
  },
  {
    icon: <BsYoutube size="1.5rem" color="#FF0000" />,
    to: "https://www.youtube.com/channel/UCV9kyy6lVlz8yANAPDfbPxA",
  },
  {
    icon: <BsFacebook size="1.5rem" color="#4267B2" />,
    to: "https://www.facebook.com/DanEnergyEthiopia",
  },
  {
    icon: <BsTwitter size="1.5rem" color=" #00acee" />,
    to: "https://twitter.com/DanEnergyET",
  },
  {
    icon: <BsLinkedin size="1.5rem" color="#0072b1" />,
    to: "https://www.linkedin.com/company/dan-energy-ethiopia/",
  },
];
export const navLinks = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "Tecno Park",
    to: "#itpark",
  },
  {
    name: "Dan Services",
  },
  {
    name: "Dan Technologies",
  },

  {
    name: "CSR",
    to: "/corporate-social-responsibility",
  },
  {
    name: "About Us",
    to: "/about",
  },
  {
    name: "Gallery",
    to: "/gallery",
  },
  {
    name: "Blogs & Articles",
    to: "/blog",
  },
  // {
  //   name: "Apply",
  //   to: "/apply",
  // },
  {
    name: "Vacancy",
    to: "/vacancy",
  },
  {
    name: "Contact Us",
    to: "/contact",
  },
  // {
  //   name: "Dan Acadamy",
  //   to: "#",
  // },
  // {
  //   name: "What We Do",
  //   to: "/services",
  // },
  // {
  //   name: "Careers",
  //   to: "#",
  // },
  // {
  //   name: "Gallery",
  //   to: "/gallery",
  // },
];
