import { Button, Heading, IconButton, Img, Text } from "@chakra-ui/react";
import React from "react";
import { BiRightArrowAlt } from "react-icons/bi";
import { BsArrowDown } from "react-icons/bs";

const DanFoundation = () => {
  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <br /> <br />
        <Heading>Updating</Heading>
        {/* <Img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1667898271/dan_Foundation_hkrwar.png" /> */}
      </div>
      <IconButton
        className="IconButton"
        aria-label="right-arrow"
        colorScheme="white"
        borderRadius="full"
        position="absolute"
        transform={"translate(0%, -50%)"}
        zIndex={2}
      >
        <BsArrowDown />
      </IconButton>
    </>
  );
};

export default DanFoundation;
