import { Heading } from "@chakra-ui/react";
import React from "react";
import BlogPostHead from "../../../Helmet/helmet";
import '../abreham/art.css'

const AbrehamArticle = () => {
  return (
    <div  className="flex flex-col items-center justify-center min-h-screen bg-gray-100"><section className="content">
    <article>
    <p>
        <img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1700559463/Article/WhatsApp_Image_2023-11-21_at_12.37.29_5693b7be_kdq44b.jpg" />
      </p><br></br>
      <p>
      My personal journey with Dan Energy began after graduating from Bahir Dar University when I became a beneficiary of @DanEnergyET's @BridgesEthiopia program in collaboration with @FirstConsultEth and @MastercardFdn. This program played an important role in empowering me to enhance my soft and hard skills and working to my subsequent role at Dan Energy as a Wind Turbine Design Engineer in the Renewable Energy department from Monday to Friday. On Saturdays and Sundays, I proudly serve as the coordinator and mentor in the #SatMent and #SundayMent programs.
Greetings to our esteemed community! I am Abreham Tilahun, a proud graduate of Bahir Dar University and currently the head of the transformative SatMent and SundayMent programs at Dan Energy. Today, I am thrilled to share the inspiring evolution of these initiatives that are playing a pivotal role in shaping the destinies of ambitious young minds.

      </p><br></br>
      
      <p>
      SatMent on Saturdays and SundayMent on Sundays are not just conventional tutoring sessions; they stand as beacons of hope for those facing educational challenges due to financial constraints. Guiding these programs, I have witnessed the profound impact that education can have on individuals, and it is this impact that fuels my passion for coordinating and mentoring SatMent and SundayMent.

      </p><br></br>
      <p>
        <img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1700559189/Article/WhatsApp_Image_2023-11-21_at_07.40.31_7e27bdd3_gygh27.jpg" />
      </p><br></br>
      <p>
      Our journey transcends the boundaries of textbooks. While we enhance skills in core subjects such as math, physics, and English, we go even deeper by introducing participants to the latest technologies. We are not solely imparting knowledge; we are cultivating a forward-thinking mindset, preparing students to confront the challenges of tomorrow with confidence.

      </p><br></br>
      <p>
      I am particularly excited to share the integral role played by our dedicated team of volunteer engineers and professional teachers in collaboration with Dan Energy Ethiopia’s SatMent and SundayMent. Our professional teachers bring their expertise, ensuring a high-quality learning environment, while our engineers contribute technical knowledge and mentorship. Together, they create an environment where knowledge flows not only from teacher to student but also among peers, fostering a community of collaboration and support.

      </p><br></br>
      <p>
      At Dan Energy Ethiopia, our commitment to these initiatives goes beyond a mere corporate checkbox; it is a genuine investment in the future. Through SatMent and SundayMent, we are not just closing educational gaps; we are unlocking doors to opportunities that once seemed out of reach. Our team of engineers, professional teachers, and passionate educators contribute to a holistic learning experience that equips students for the challenges and opportunities of the real world.

      </p><br></br>
      <p>
      It's important to highlight that the students in #SatMent and #SundayMent come from all Addis Ababa and surrounding areas, mainly from lower-income backgrounds. Our mission is to break down the barriers to education and personal growth caused by financial constraints. Moreover, we aspire to democratize education, ensuring that everyone, regardless of their background, has equal access to educational opportunities.

      </p><br></br>
      <p>
        <img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1700559199/Article/WhatsApp_Image_2023-11-21_at_12.10.03_34f0461d_j6w0uc.jpg" />
      </p><br></br>
      <p>Dan Energy has impacted lives significantly through these programs, generating success stories that serve as a testimony to the transformative power of education. From providing scholarships to facilitating skill development, we have witnessed the positive impact that these initiatives create within communities.
</p><br></br>
<p>We invite others to work with Dan Energy, whether through mentoring, volunteering as teachers, or exploring various support avenues. Embracing diverse ways of joining us allows us to collectively amplify our impact and extend our reach to more young minds. Together, let's shape a future where education is a driving force for positive, lasting change within communities. The journey is ongoing, and with your support, we can light the way for the next generation. As a concluding thought, we warmly welcome anyone interested in visiting our programs, experiencing the positive impact firsthand, and becoming part of the transformative journey we are undertaking. Together, let us inspire the continuation of legacies and foster a timeless transformation that extends far beyond the foreseeable future, forging a significant and everlasting Historical remark.</p><br></br>
<p>
        <img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1700560077/Article/WhatsApp_Image_2023-11-21_at_12.46.53_6b63f7f4_bagxcv.jpg" />
      </p>
<div style={{ marginTop: '20px', borderTop: '1px solid #ccc', paddingTop: '10px' }}>
        <p><a href="mailto:Abreham@danenergyethiopia.com">Abreham@danenergyethiopia.com</a></p><br></br>
      </div></article>
    <aside>
    <img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1700559189/Article/WhatsApp_Image_2023-11-21_at_07.40.31_7e27bdd3_gygh27.jpg" />
      <img src="https://res.cloudinary.com/ddyvv0new/image/upload/v1700559193/Article/WhatsApp_Image_2023-11-21_at_07.40.31_082a5905_dcvu2z.jpg" />
      
    </aside>
  </section></div>
    

  );
};

export default AbrehamArticle;
